<template>
  <div id="app">
    <div v-if="$route.name !== 'LandingPage'">
      <!-- Your header, settings modal, evaluation content, etc. here -->
    <div class="content">
      <div class="header">
        <a href="/">
      <img src="./assets/logo.png" alt="AIVAL Logo" class="logo">
        </a>
        <h1></h1> 
        <button class="icon" @click="isModalOpen = true">
          <font-awesome-icon icon="gear" />
        </button>
      </div>
      <div class="title">
        <h2>Your Guide to Generative AI Implementation</h2> 
      </div>
      <div class="introduction">  
        <p>AIVAL uses a sophisticated and customisable weighted scorecard model, to guide you through a series of questions designed to assess the viability and potential impact of AI in your specific context. With your scores in hand, AIVAL provides a clear recommendation based on your unique needs and priorities.</p>
        <p>The scorecard is in two parts. Part one focuses on alignment to strategy, scalability, and benefit realisation. Part 2 focuses on risk.</p>
        <p>Once you have answered each part, click the Evaluation button to reveal the recommendation based on your scores.</p>
        <p>Tip: click the settings icon to change the question weights and the score thresholds</p>
      </div>
      <!-- Modal Structure -->
      <div v-if="isModalOpen" class="modal">
        <div class="modal-content">
          <button @click="closeModal" class="close-button">
            <font-awesome-icon icon="times" />
          </button>
          <!-- Conditionally render the SettingsComponent -->
          <settings-component
            :evaluation="evaluation"
            :scoreInterpretations="scoreInterpretations"
            @settings-saved="settingsSaved"
            @settings-canceled="closeModal"
          />
        </div>
      </div>
      <!-- Loop through each part -->
      <div v-for="(part, partIndex) in evaluation" :key="partIndex" class="part-card">
        <div @click="togglePart(partIndex)" class="part-header">
          <div class="part-title-wrapper">
            <h2 class="part-title">{{ part.partTitle }}</h2>
            <font-awesome-icon :icon="part.isCollapsed ? 'chevron-down' : 'chevron-up'" />
          </div>
          <p class="part-description">{{ part.partDescription }}</p>
        </div>
        <div v-if="!part.isCollapsed">
          <!-- Loop through each group within the part -->
          <div v-for="(group, groupIndex) in part.groups" :key="groupIndex">
            <h3 class="group-name">{{ group.groupName }}</h3>
            <div class="questions-container">
              <!-- Loop through each question within the group -->
              <question-component
                v-for="(question, questionIndex) in group.questions"
                :key="questionIndex"
                :question="question"
                @score-changed="handleScoreChange($event, partIndex, groupIndex, questionIndex)"
              />
            </div>
          </div>
        </div>
      </div>
      <h3 class="totalscore">Total Score: {{ totalScore }}</h3>
      <div class="part-card">
      <evaluate-component
        :total-score="totalScore"
        :max-score="maxScore"
        :score-interpretations="scoreInterpretations"
      ></evaluate-component>
      </div>
    <div class="lower-buttons-container">
      <div class="import-button-container">
        <import-component @file-loaded="loadEvaluation"></import-component>
      </div>
      <div class="save-button-container">
        <save-component
          :evaluation="evaluation"
          :total-score="totalScore"
        ></save-component>
      </div>
      </div>
    </div>
  </div>
    <router-view></router-view>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import QuestionComponent from './components/QuestionComponent.vue';
import SettingsComponent from './components/SettingsComponent.vue';
import EvaluateComponent from './components/EvaluateComponent.vue';
import SaveComponent from './components/SaveComponent.vue';
import ImportComponent from './components/ImportComponent.vue';

export default {
  name: 'App',
  components: {
    QuestionComponent,
    SettingsComponent,
    EvaluateComponent,
    SaveComponent,
    ImportComponent
  },
  data() {
    return {
      isModalOpen: false, // Controls the visibility of the modal
      scoreInterpretations: [
        { threshold: 0, description: "Do not implement", color: "#FF6B6B" },
        { threshold: 20, description: "Needs significant rework", color: "#FFA500"},
        { threshold: 40, description: "Consider Redesigning Solution", color: "#FCFF52" },
        { threshold: 60, description: "Implement Small Scale for further evaluation", color: "#89CFF0" },
        { threshold: 80, description: "Implement Use Case", color: "#2ECC71" }
      ],
      // Hierarchical data structure for parts, groups, and questions
      evaluation: [
        {
          partTitle: "Part 1",
          partDescription: "Score 0 to 5, where 0 indicates 'not satisfactory at all' and 5 represents 'exceeds expectations'",
          isCollapsed: true,
          groups: [
            {
              groupName: "Strategic Alignment",
              weight: 1,
              questions: [
                { text: "Does the solution align with the strategic objectives of your organisation?", score: 0, weight: 1 },
                { text: "Could the implementation of this AI solution provide a competitive advantage in your industry?", score: 0, weight: 1 },
                { text: "Did the solution show it could achieve its intended objective?", score: 0, weight: 1 },
                { text: "Has an ROI model been identified?", score: 0, weight: 1 },
                // Add additional questions as needed
              ]
            },
            {
              groupName: "Performance & Reliability",
              weight: 1,
              questions: [
                { text: "How effective was the solution in addressing the defined problem and performing the intended tasks?", score: 0, weight: 1 },
                { text: "Can the solution scale according to the organizational needs?", score: 0, weight: 1 },
                { text: "How reliable is the AI solution in various operational conditions?", score: 0, weight: 1 },
                { text: "Is there an opportunity to leverage this AI solution beyond just the intended objective?", score: 0, weight: 1 },
                // Add additional questions as needed
              ]
            },
            {
              groupName: "User Impact",
              weight: 1,
              questions: [
                { text: "How satisfied were the users with the solution?", score: 0, weight: 1 },
                { text: "How easy is it for users to adopt and adapt to the new AI solution?", score: 0, weight: 1 },
                // Add additional questions as needed
              ]
            },
            {
              groupName: "Business Impact",
              weight: 1,
              questions: [
                { text: "What was the solution's impact on business processes?", score: 0, weight: 1 },
                { text: "How much could business metrics change due to the solution?", score: 0, weight: 1 },
                // Add additional questions as needed
              ]
            }
          ]
        },
        {
          partTitle: "Part 2",
          partDescription: "Score 0 to 5, where 0 indicates 'no risk' and 5 represents 'Very high.'",

          isCollapsed: true,
          groups: [
            {
              groupName: "Legal & Ethical Compliance",
              weight: 1,
              questions: [
                { text: "Does the solution introduce issues with data privacy?", score: 0, weight: 1 },
                { text: "Have any ethical concerns identified with the solution?", score: 0, weight: 1 },
                { text: "Have issues with transparency of the responses provided been indentified?", score: 0, weight: 1 },
                // Add additional questions as needed
              ]
            },
            {
              groupName: "Risk",
              weight: 1,
              questions: [
                { text: "Does the solution introduce any operational or reputational risks?", score: 0, weight: 1 },
                { text: "What is the likelihood of these risks occurring?", score: 0, weight: 1 },
                { text: "What would the potential impact be of these risks occurring?", score: 0, weight: 1 },
                // Add additional questions as needed
              ]
            }
          ]
        }
      ],
      showSettings: true,
      showResults: false, // Controls visibility of evaluation results
    };
  },
  computed: {
    totalScore() {
      let score = this.evaluation.reduce((acc, part, partIndex) => {
        let groupScores = part.groups.reduce((groupAcc, group) => {
          let questionScore = group.questions.reduce((questionAcc, question) => {
            return questionAcc + question.score * question.weight;
          }, 0);
          return groupAcc + (questionScore * group.weight); // Multiply by group weight
        }, 0);

        return partIndex === 0 ? acc + groupScores : acc - groupScores;
      }, 0);

      return score;
    },
    maxScore() {
      // Only consider Part 1 for max score calculation
      const part1 = this.evaluation.find(part => part.partTitle === "Part 1");
      if (!part1) return 0;

      return part1.groups.reduce((groupAcc, group) => {
        return groupAcc + (group.questions.length * 5 * group.weight);
      }, 0);
    }
  },
  methods: {
    setDocumentTitle(title) {
      document.title = title;
    },
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;  // This should directly control the modal's visibility
      this.showSettings = false;  // Ensure settings are not visible when modal is closed
    },
    togglePart(index) {
      this.evaluation[index].isCollapsed = !this.evaluation[index].isCollapsed;
    },
    handleScoreChange(newScore, partIndex, groupIndex, questionIndex) {
      this.evaluation[partIndex].groups[groupIndex].questions[questionIndex].score = newScore;
    },
    evaluateScore() {
      this.showResults = !this.showResults; // Toggle the results display
    },
    settingsSaved(data) {
      this.evaluation.forEach((part, partIndex) => {
        part.groups.forEach((group, groupIndex) => {
          group.weight = data.groupWeights[partIndex][groupIndex];
        });
      });
      this.scoreInterpretations = data.scoreInterpretations;
      this.closeModal();
    },
    resetEvaluation() {
    // Set your evaluation to its default state
    this.evaluation = []; // or however you define the default state
    // Repeat for any other data properties that might need resetting
  },

  loadEvaluation(importedData) {
    console.log("Before reset:", JSON.parse(JSON.stringify(this.evaluation)));
    // First, reset the current evaluation data to defaults
    this.resetEvaluation();

    if (!importedData || !Array.isArray(importedData.evaluation)) {
      console.error("The imported file does not contain valid evaluation data.");
      return;
    }

    // Now that the state has been reset, proceed with loading the new data
    const clonedEvaluation = importedData.evaluation.map(part => ({
      ...part,
      groups: part.groups.map(group => ({
        ...group,
        questions: group.questions.map(question => ({
          ...question,
          score: parseInt(question.score, 10) // Ensure 'score' is an integer
        }))
      }))
    }));

    // Assign the new evaluation data
    this.evaluation = clonedEvaluation;

    console.log("After import:", JSON.parse(JSON.stringify(this.evaluation)));
  }
  },
  mounted() {
    this.setDocumentTitle('AIVAL - AI Solution Evaluation');
  }
};
</script>

<style scoped>
/* Add your CSS styling here */
#app {
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}

.logo {
  width: 120px;
}

.content {
  max-width: 800px; /* Adjust based on your preferred width */
  margin: auto; /* This centers the .content div horizontally */
  text-align: left; /* Aligns all text inside the .content div to the left */
}

.header {
  display: flex;
  justify-content: space-between; /* Spreads out the children to each end */
  align-items: center; /* Vertically centers the children in the line */
}

.title {
  text-align: center;
}

.introduction {
  margin-bottom: 2em;
  text-align: justify;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* High z-index to cover other content */
  overflow-y: auto;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  width: 90%; /* Or any fixed or percentage width depending on your design needs */
  max-width: 600px; /* Optional, for responsive width */
  margin: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

@media (max-width: 600px) {

  .header .logo {
    margin-bottom: 5px;
  }
  .header h1 {
    font-size: 1.3em;
    margin-bottom: 5px;
    margin-top: 0px;
  }
  .header .icon {
    margin-bottom: 10px;
  }

  .modal-content {
    padding: 15px; /* Slightly smaller padding on smaller screens */
    width: 95%; /* Use more of the screen's width */
  }
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background: none;
  border: none;
  font-size: 24px;
  color: #333;
}

.close-button {
  position:absolute;
  top: 5px;
  margin-top: 10px !important;
  right: 10px;
  cursor: pointer;
  background: none !important;
  border: none;
  font-size: 24px !important;
  color: #737373 !important;
}

.part-header {
  display: flex;
  flex-direction: column;
}

.part-title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.part-description {
  margin-top: 0;
  margin-left: 0;
  margin-bottom: 5px;
}

.chevron {
  cursor: pointer;
  color: #13876f;
}

.part-card {
  background-color: #fff; /* White background for the card */
  border-radius: 8px; /* Rounded corners for the card */
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Subtle shadow for depth */
  padding: 20px; /* Padding inside the card */
  margin-bottom: 20px; /* Space between each card */
  cursor: pointer; /* Indicate that the part card can be clicked */
}

.part-title, .group-name {
  margin-left: 0px; /* Provides a bit of padding from the edge */
  margin-top: 10px;
}

.question {
  margin-left: 20px; /* Further indents questions under groups */
  margin-bottom: 10px;
}

.icon, .icon-close {
  background-color: transparent !important; /* Important to make the icon button's background transparent */
  color: #13876f !important; /* Set the icon color */
  font-size: 1.4em !important; /* Set the icon size */
  padding: 5px; /* Minimal padding */
  margin: 0; /* No margin to keep it tightly controlled */
  border: none; /* No border for a cleaner look */
}

#app button {
  background-color: #16a085; /* A bright blue that stands out */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 5px; /* Rounded corners */
  font-size: 16px; /* Slightly larger font size for readability */
  cursor: pointer;
  outline: none; /* Removes the outline to keep the flat design */
  transition: background-color 0.3s, transform 0.2s; /* Smooth transitions on hover */
}

#app button:hover {
  background-color: #13876f; /* A darker blue on hover */
}

#app button:active {
  background-color: #0f6659; /* Even darker on active (click) */
}

h1 {
  font-size: 2em;
  margin-bottom: 20px;
  font-weight: 500
}

h2 {
  font-size: 1.5em;
  font-weight: 500;
  color: #34495e;
}

h3 {
  font-size: 1.2em;
  font-weight: 500;
  color: #16a085;
}

.questions-container {
  display: flex;
  flex-direction: column;
}

.totalscore {
  text-align: right;
  margin-right: 5px;
  font-size: 22px;
}
.save-button-container {
  margin-top: 0;
}
.import-button-container{
  margin-top: 10px;
}
.lower-buttons-container {
  display: none;
  justify-content: space-between;
}
</style>
