<template>
    <button @click="saveData">Export Evaluation</button>
  </template>
  
  <script>
  export default {
    props: {
      evaluation: Array,
      totalScore: Number
    },
    methods: {
      async saveData() {
        // Generate structured data from props
        const structuredData = {
          evaluation: this.evaluation.map(part => ({
            partTitle: part.partTitle,
            partDescription: part.partDescription,
            groups: part.groups.map(group => ({
              groupName: group.groupName,
              weight: group.weight,
              questions: group.questions.map(question => ({
                text: question.text,
                score: question.score,
                weight: question.weight
              }))
            }))
          })),
          totalScore: this.totalScore
        };
  
        // Stringify the structured data
        const jsonData = JSON.stringify(structuredData, null, 2);
  
        // Call your function with filename
        this.triggerDownload(jsonData, 'evaluation-results.json');
      },
      triggerDownload(jsonData, fileName) {
        const blob = new Blob([jsonData], { type: 'application/json' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(link.href); // Clean up after download
      }
    }
  };
  </script>
  