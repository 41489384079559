<template>
  <div class="import-container">
    <!-- The label acts as a proxy for the hidden file input -->
    <label for="file-upload" class="custom-file-upload">
      Import File
    </label>
    <!-- The actual file input is hidden using CSS -->
    <input id="file-upload" type="file" @change="loadFile" style="display: none;" />
  </div>
</template>

<script>
export default {
  name: 'ImportComponent',
  methods: {
    loadFile(event) {
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        try {
          const jsonData = JSON.parse(e.target.result);
          this.$emit('file-loaded', jsonData);
        } catch (error) {
          console.error("The file could not be parsed as JSON.", error);
        }
      };
      fileReader.readAsText(event.target.files[0]);
    }
  }
};
</script>

<style scoped>
.custom-file-upload {
  padding: 10px 20px;
  cursor: pointer;
  background-color: #16a085; /* Bootstrap primary color example */
  color: white;
  border-radius: 5px;
  border: 1px solid transparent;
  transition: background-color 0.15s ease-in-out;

  /* Add any additional button-like styling you need here */
}

.custom-file-upload:hover,
.custom-file-upload:focus {
  background-color: #13876f; /* Darkened background on hover/focus */
}
</style>