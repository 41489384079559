<template>
  <div class="settings">
    <h2>Settings</h2>
    <h3>Group Weightings</h3>
    <p>By default each group of questions has a weighting of 1. Changing the weighting of a group will impact the scores for all questions in that group are calculated.</p>
    <p>Example: a weighting of 1.5 for Risk will multiply the scores for those questions by 1.5x.</p>
    <p>Which weightings you use is entirely up to what your organisation regards as important.</p>
    <div class="part" v-for="(part, partIndex) in evaluation" :key="partIndex">
      <h4>{{ part.partTitle }}</h4>
      <div class="group" v-for="(group, groupIndex) in part.groups" :key="groupIndex">
        <label>{{ group.groupName }}</label>
        <input type="number" min="0.1" step="0.1" v-model.number="group.weight" />
      </div>
    </div>
    <h3>Score Thresholds</h3>
    <p>Thresholds are used to evaluate your score against a range of possible recommendations for your solution.</p>
    <p>They are expressed as %</p>
    <p>You can change both the threshold number and the description text below. You can also add or remove threshold levels</p>
    <div class="threshold-item" v-for="(interpretation, index) in localInterpretations" :key="index">
      <input type="number" v-model.number="interpretation.threshold" placeholder="Score %" min="0" max="100" />
      <input class="thresholdinput" type="text" v-model="interpretation.description" placeholder="Description" />
      <input type="color" v-model="interpretation.color" /> <!-- Color picker input -->
      <button class="thresholdbutton" @click="removeThreshold(index)">
        <font-awesome-icon icon="xmark" />
      </button>
    </div>
    <button class="mainbutton" @click="addThreshold">Add New Threshold</button>
    <button class="mainbutton" @click="saveSettings">Save All Settings</button>
    <button class="mainbutton" @click="cancelSettings">Cancel</button>
  </div>
</template>

<script>
export default {
  name: 'SettingsComponent',
  props: {
    evaluation: Array,
    scoreInterpretations: {
    type: Array,
    default: () => []
  }
  },
  data() {
    return {
      localInterpretations: [] // This will hold a local, editable copy of scoreInterpretations
    };
  },
  created() {
    this.initInterpretations(); // Initialize local interpretations on component creation
  },
  methods: {
    initInterpretations() {
      // Initialize local interpretations based on the prop
      if (Array.isArray(this.scoreInterpretations)) {
        this.localInterpretations = this.scoreInterpretations.map(interp => ({
          threshold: interp.threshold,
          description: interp.description,
          color: interp.color || '#16a085' // Default color if not set
        }));
      } else {
      console.error('Invalid scoreInterpretations:', this.scoreInterpretations);
      this.localInterpretations = [];
    }
    },
    addThreshold() {
      this.localInterpretations.push({ threshold: 0, description: '', color: '#16a085' });
    },
    removeThreshold(index) {
      this.localInterpretations.splice(index, 1);
    },
    saveSettings() {
      this.$emit('settings-saved', {
        groupWeights: this.evaluation.map(part => part.groups.map(group => group.weight)),
        scoreInterpretations: this.localInterpretations
      });
      this.cancelSettings();
    },
    cancelSettings() {
      this.$emit('settings-canceled');
      this.initInterpretations(); // Re-initialize to revert any unsaved changes
    }
  },
  watch: {
    scoreInterpretations: {
      deep: true,
      immediate: true, // Also react to immediate changes when the component is mounted
      handler() {
        this.initInterpretations();
      }
    }
  }
};
</script>
<style scoped>
.settings {
  background-color: #f9f9f9; /* Light grey background for the settings panel */
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #e1e1e1; /* Subtle border */
}

.settings p {
  font-size: 16px;
  font-weight: 400;
}
.settings h2{
  margin: 0%;
  font-weight: 500;
}

.settings h3{
  font-weight: 500;
}

.settings h4 {
  margin-bottom: 10px; /* Space below each group title */
  font-size: 18px;
  font-weight: 600;
}

.settings label {
  margin-right: 10px;
  margin-bottom: 5px; /* Space between the label and the input field */
}

.settings input[type="number"] {
  width: 40px; /* Fixed width for the input */
  padding: 5px;
  text-align: center;
  margin-bottom: 10px; /* Space below the input field */
  border: 1px solid #ccc; /* A subtle border for the input */
  border-radius: 4px; /* Slightly rounded corners for the input */
}

.settings input[type="text"] {
  width: 60px; /* Fixed width for the input */
  padding: 5px;
  margin-bottom: 10px; /* Space below the input field */
  border: 1px solid #ccc; /* A subtle border for the input */
  border-radius: 4px; /* Slightly rounded corners for the input */
}

.group {
  display: flex;
  align-items: center; /* Vertically center align the items */
  margin-bottom: 10px; /* Spacing between each group for clarity */
}

.group label {
  margin-right: 10px; /* Spacing between label and input */
  white-space: nowrap; /* Prevents the label from breaking into multiple lines */
  flex-shrink: 0; /* Prevents the label from shrinking */
  flex: 0 0 250px;
}

.group input {
  flex: 0 0 40px; /* Overrides the default width to fill flex container */
}

.mainbutton {
  background-color: #16a085; /* A bright blue that stands out */
  border: none;
  color: white;
  margin-top: 10px;
  padding: 10px 20px;
  border-radius: 5px; /* Rounded corners */
  font-size: 16px; /* Slightly larger font size for readability */
  cursor: pointer;
  outline: none; /* Removes the outline to keep the flat design */
  transition: background-color 0.3s, transform 0.2s; /* Smooth transitions on hover */
}

.mainbutton {
  margin-right: 10px; /* Adds right margin to both buttons */
}

.mainbutton:last-child {
  margin-right: 0; /* Removes right margin from the last button */
}

.mainbutton:hover {
  background-color: #13876f; /* A darker blue on hover */
}

.mainbutton:active {
  background-color: #0f6659; /* Even darker on active (click) */
}

.threshold-item {  
  display: flex;
  align-items: center; 
  margin-bottom: 10px;
}

.threshold-item input[type="color"] {
  width: 20px; /* Makes the color picker square */
  height: 20px; /* Adjust height to match width, making it square */
  border: none; /* Optional: removes the border */
  cursor: pointer;
  margin-left: 10px; /* Space from the description input */
  margin-bottom: 10px;
  padding: 0; /* Removes padding to avoid visual misalignment */
  align-self: center;
}

/* Adjust the internal color picker appearance for WebKit browsers */
.threshold-item input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}

.threshold-item input[type="color"]::-webkit-color-swatch {
  border: none;
}

.thresholdinput {
  margin-left: 20px;
  min-width: 300px;
}

.thresholdbutton {
  margin-left: 10px;
  margin-bottom: 10px;
  font-size: 22px;
  background: none;
  background-color: none;
  border: none;
  cursor: pointer;
  color: rgb(97, 0, 0);
}
</style>