<template>
  <div>
    <div ref="gaugeChart" style="width: 100%; height: 500px;"></div>
    <div class="recommendation">
      <h3>Recommendation: {{ interpretation }}</h3>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  name: 'GaugeChart',
  props: ['scorePercentage', 'interpretation', 'scoreInterpretations'],
  mounted() {
    this.initChart();
  },
  methods: {
    initChart() {
      const chart = echarts.init(this.$refs.gaugeChart);
      // Sort scoreInterpretations from highest threshold to lowest
      const sortedInterpretations = [...this.scoreInterpretations].sort((a, b) => b.threshold - a.threshold);
      const currentInterpretation = sortedInterpretations.find(interp => this.scorePercentage >= interp.threshold) || { color: '#eee' };

      const option = {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c}% ({d}%)'
        },
        series: [
          {
            name: 'Score',
            type: 'pie',
            radius: ['50%', '70%'],
            center: ['50%', '50%'],
            startAngle: 180,
            endAngle: 0,
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: true,
                position: 'center',
                formatter: () => `${this.scorePercentage}%`,
                textStyle: {
                  fontSize: '40',
                  fontWeight: 'bold'
                }
              }
            },
            emphasis: {
              label: {
                show: true,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: this.scorePercentage, name: 'Score Achieved', itemStyle: { color: currentInterpretation.color } },
              { value: 100 - this.scorePercentage, name: 'Remaining', itemStyle: { color: '#eee' } }
            ]
          }
        ]
      };

      chart.setOption(option);
      window.addEventListener('resize', () => {
        chart.resize();
      });
    }
  },
  watch: {
    scorePercentage() {
      this.initChart(); // Reinitialize the chart if the score changes
    },
    scoreInterpretations: {
      deep: true, // This is necessary to catch nested changes
      handler() {
        this.initChart(); // Reinitialize if interpretations change
      }
    }
  }
}
</script>


<style scoped>
.recommendation {
  text-align: center;
  margin-top: -200px;
}

h3 {
  font-size: 1.2em;
  font-weight: 600;
  color: #16a085;
  margin: 0;
}
</style>
