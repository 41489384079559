<template>
  <div class="question-item">
    <label>{{ question.text }}</label>
    <input type="range" min="0" max="5" v-model="localScore" @change="updateScore"/>
    <span class="question-score">{{ localScore }}</span>
  </div>
</template>

<script>
export default {
  name: 'QuestionComponent',
  props: {
    question: Object
  },
  data() {
    return {
      localScore: this.question.score
    };
  },
  methods: {
    updateScore() {
      this.$emit('score-changed', this.localScore);
    }
  }
};
</script>

<style scoped>
.question-item {
  display: flex;
  align-items: center; /* Aligns the label and slider on their centerline */
  margin-bottom: 20px;
}

.question-item label {
  flex: 1; /* Allows the label to take up any remaining space */
  margin-right: 20px; /* Provides some spacing between the label and the slider */
}

.question-item input[type="range"] {
  flex: 0 0 200px; /* Gives the slider a fixed width and prevents it from growing */
}
@media (max-width: 768px) {
  .question-item {
    flex-direction: column; /* Stacks the slider underneath the question */
    margin-bottom: 5px; /* Reducing space below the item on mobile */
    align-items: stretch; /* Makes children (label and range input) fill the container */
  }
  .question-item label {
    margin-bottom: 10px; /* Adds space between the label and the range input */
    margin-right: 0; /* Removes the margin to the right of the label */
  }

  .question-item input[type="range"] {
    flex: 1; /* Allows the slider to grow and fill available space */
    width: auto; /* Resets any specific width settings */
    margin: 0; /* Reset margins to reduce space around the slider */
  }
  .question-score {
    text-align: center;
    margin-top: 10px;
  }
}
</style>
