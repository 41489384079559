import { createRouter, createWebHistory } from 'vue-router';
import LandingPage from '@/components/LandingPage.vue';

const routes = [
    // other routes
    {
        path: '/',
        redirect: '/landing',
      },
    {
        path: "/landing",
        name: "LandingPage",
        component: LandingPage,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
  });
  
  export default router;