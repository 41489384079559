// Importing Vue component and library core
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';  // import all solid icons

// Optional: Import specific icons to reduce bundle size
//import { faUserSecret } from '@fortawesome/free-solid-svg-icons';

// Adding icons to the library
library.add(fas);  // Add all solid icons, or just specific ones

// Export a function to install the plugin
export default {
  install: (app) => {
    app.component('font-awesome-icon', FontAwesomeIcon);
  }
};
