<template>
  <div class="evaluate-container">
    <div class="button-container">
      <h2> Recommendation</h2>
    <button @click="toggleResults">{{ showResults ? 'Close' : 'Evaluate' }}</button> 
    </div>
    <div v-if="showResults">
      <p>
        Based on your assessed score the recommendation is as follows:
      </p>
      <!-- Gauge chart component -->
      <gauge-chart 
        :score-percentage="scorePercentage"
        :interpretation="interpretation"
        :score-interpretations="scoreInterpretations"
      ></gauge-chart>
    </div>
  </div>
</template>

<script>
import GaugeChart from './GaugeChart.vue'; // Importing the GaugeChart component

export default {
  name: 'EvaluateComponent',
  components: {
    GaugeChart // Registering the GaugeChart component
  },
  props: {
    totalScore: Number,
    maxScore: Number,
    scoreInterpretations: Array
  },
  data() {
    return {
      showResults: false,
    };
  },
  computed: {
    scorePercentage() {
      // Compute the score as a percentage
      if (this.maxScore === 0) return 0;
      return Math.round((this.totalScore / this.maxScore) * 100);
    },
    interpretation() {
      // Determine the interpretation based on score percentage
      if (!this.scoreInterpretations || this.scoreInterpretations.length === 0) {
        return "No interpretation set";
      }
      const sortedInterpretations = this.scoreInterpretations.slice().sort((a, b) => b.threshold - a.threshold);
      return sortedInterpretations.find(interpretation => this.scorePercentage >= interpretation.threshold)?.description || "No clear interpretation";
    }
  },
  methods: {
    toggleResults() {
      // Toggle the display of results
      this.showResults = !this.showResults;
    }
  }
}
</script>

<style scoped>
.evaluate-container {
  display: flex;
  flex-direction: column;
}

.button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
h2 {
  font-size: 24px;
  font-weight: 500;
  color: #16a085;
  margin-right: auto;
}
p {
  font-size: 1em;
  font-weight: 400;
}

button {
  background-color: #16a085; /* A bright blue that stands out */
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 5px; /* Rounded corners */
  font-size: 16px; /* Slightly larger font size for readability */
  cursor: pointer;
  outline: none; /* Removes the outline to keep the flat design */
  transition: background-color 0.3s, transform 0.2s; /* Smooth transitions on hover */
}
</style>
