<template>
  <div>
   <section id="hero" class="hero-container">
      <!-- Hero section content -->
      <div class="hero-content">
        <div class="hero-text">
          <!-- Your text content goes here -->
          <img src="../assets/AIVALLogo-green-transparent.png" alt="AIVAL Logo" class="logo">
          <h1>Generative AI solution evaluation made easy!</h1>
          <p>AIVAL equips business leaders with a comprehensive tool for assessing AI solutions, aiding in making informed decisions on implementation based on strategic alignment, risk evaluation, and potential business impact.</p>
          <div class="features-header">
        <div class="feature-column">
          <img src="/icons/score.png">
          <h3>Weighted scoring model</h3>
          <p>Reduces bias and ensures the evaluation of AI solutions accurately represents the significance of different factors in the overall decision-making.</p>
        </div>
        <div class="feature-column">
          <img src="/icons/formula.png">
          <h3>Calculated Recommendation</h3>
          <p>Provides a data-driven, objective decision tool, guiding organisations towards the most beneficial AI solutions in alignment with their specific needs and priorities.</p>
          </div>
          <div class="feature-column">
          <img src="/icons/assessment.png">
          <h3>Configurable</h3>
          <p>Configurable model weightings and recommendation thresholds allow for a customised evaluation of AI solutions, aligning the assessment closely with an organisation's unique needs and risk tolerance.</p>
        </div> 
        </div>
        <div class="hero-button-container">         
          <button @click="goToApp">Get Started</button>
        </div>
        </div>
      </div>
   </section>

   <section id="testimonials">
      <!-- Testimonials section content -->
      <div class="testimonials-container">
        <h2>Testimonials</h2>
        <div class="testimonial">
          <blockquote>"AIVAL has transformed how we approach deciding whether we move from Proof of Concept to a pilot or Production solution. It encourages us to use our curiosity and explore what can be done with this fast moving technology, but causes us to then pause and reflect on whether we should do something at scale."</blockquote>
          <cite>- Senior AI Executive</cite>
        </div>
        <!-- Add more testimonials as needed -->
      </div>
    </section>

    <!-- 
    <section id="how-it-works">
      
      <div class="how-it-works-container">
        <h2>How It Works</h2>
        <p>Step by step guide on how our product makes things better.</p>
      </div>
    </section>
    -->

    <section id="cta">
      <!-- Call to action section content -->
      <div class="cta-container">
        <button class="button-body" @click="goToApp">Get Started</button>
      </div>
    </section>

    <footer>
      <div class="footer-container">
        <p>&copy; 2024 Unlok. All rights reserved.</p>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'LandingPage',
  methods: {
    goToApp() {
      this.$router.push('/app');
    },
  },
};
</script>

<style scoped>
  /* Additional styles for your components */

  .navbar {
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
    font-family: 'Arial', sans-serif;
    padding: 10px 0;
  }

  .nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }

  .logo {
    height: 50px;
  }

  .nav-links {
    list-style: none;
    display: flex;
    align-items: center;
  }

  .nav-links li + li {
    margin-left: 20px;
  }

  .nav-links a {
    color: #333333;
    text-decoration: none;
    transition: color 0.3s ease;
  }

  .nav-links a:hover {
    color: #007BFF;
  }

  .hero-button-container {
    text-align: center;
  }

  .hero-text button {
    background-color: #FFFFFF; /* A bright blue that stands out */
    border: none !important;
    color: #16a085;
    padding: 10px 20px;
    margin-top: 30px;
    margin-bottom: 20px;
    border-radius: 20px; /* Rounded corners */
    font-size: 16px; /* Slightly larger font size for readability */
    cursor: pointer;
    outline: none; /* Removes the outline to keep the flat design */
    transition: background-color 0.3s, transform 0.2s; /* Smooth transitions on hover */
  }

  .hero-text button:hover {
    background-color: #16a085;
    color: #FFFFFF;
  }

  .hero-container {
    max-width: 100%;
    min-height: 600px; 
    margin: 0 auto;
    display: flex;
    justify-content: center;
    text-align: left;
    background-color: #FFFFFF;
    background-position: center;
    background: linear-gradient(rgba(22, 160, 133, 0.9), rgba(22, 160, 133, 0.9)),
                url('../../public/hero_image2.jpg') no-repeat center center;
    background-size: cover;
    z-index: 1;
  }

  .hero-content {
    display: flex; /* Use flexbox for inner layout */
    justify-content: center;
    width: 80%;
  }
  
  .hero-text {
    flex-basis: 100%;
    max-width: 1200px;
    flex-grow: 1;
  flex-shrink: 0;
    padding-right: 0px;
    padding-left: 0px;
    margin-top: 20px;
    color: #ffffff; /* Muted text color for the paragraph */
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 18px; /* Slightly larger paragraph font size */
    line-height: 1.6; /* More readable line height */
    z-index: 2;
  }

  .hero-text h1{
    font-size: 40px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 200;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .hero-text p{
    font-size: 20px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 200;
    margin-top: 10px;
  }

  .features-header {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

  .feature-column {
  flex: 1; /* Each column takes up equal width */
  padding-right: 20px;
  }

  .feature-column img {
     height: 30px;
  }

  .feature-column h3 {
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      font-weight: 200 !important;
      margin-top: 10px;
  }

  .feature-column p {
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      font-weight: 400;
      font-size: 16px;
  }

  .features-container, .how-it-works-container, .testimonials-container, .cta-container {
    max-width: 1200px;
    margin-top: 0px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }

  .feature-list {
    list-style: none;
    /* Style your feature list */
  }

  .testimonial {
    background-color: #f8f8f8;
    padding: 20px;
    border-left: 5px solid rgba(22, 160, 133);
    margin: 20px 0;
  }

  .testimonial blockquote {
    font-style: italic;
    font-size: 1.3em;
  }

  .testimonial cite {
    display: block;
    margin-top: 10px;
  }

  .button-body {
    background-color: #16a085;
    border: none !important;
    color: #ffffff;
    padding: 10px 20px;
    margin-top: 30px;
    margin-bottom: 20px;
    border-radius: 20px;
    font-size: 16px;
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s, transform 0.2s;
  }

  .footer-container {
    text-align: center;
    padding: 20px 0;
    background-color: #333333;
    color: #FFFFFF;
  }

  @media (max-width: 768px) {
  .features-header {
    flex-direction: column;
  }
  .hero-text button {
    margin-bottom: 20px;
  }
  .hero-container {
    max-width: 100%;
}
  }

  /* You can add more styling as needed */
</style>
